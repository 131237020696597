.cart-item {
  background-color: white;
  border: 1px solid #C2D1D9;
  border-radius: 5px;
  padding: 10px 10px 20px;
  margin-bottom: 10px;
  box-shadow: 0px 10px 25px 0px #00000012;
  width: 100%;
  transition: all ease-in-out 200ms;

  &.selected {
      border: 1px solid #3BB7D2;

      .icon.checkbox {
        border: none;
        background: linear-gradient(90.02deg, #0181BA 23.52%, #3BB7D2 100%);
      }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .icon {
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 17px;
      }

      &.bordered {
        border: 1px solid #C2D1D9;
        border-radius: 50%;
      }

      &.checkbox {
        height: 17px;
        width: 17px;
        border: 1px solid #C2D1D9;
        border-radius: 5px;
        background: white;

        img {
          width: 10px;
          height: 9px;
        }
      }

      &.map {
        svg {
          width: 17px;
          height: 17px;
          > * {
            fill: #0181BA;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      gap: 5px;
    }
  }

  &__content {
    margin-top: 10px;
    display: flex;
    gap: 10px;

    .image {
      flex-shrink: 0;
      img {
        width: 50px;
        height: 50px;
      }
    }

    &__item {
      display: flex;
      gap: 5px;
      .title {
        font-size: 13px;
        font-weight: 600;
        color: #0181BA;
      }
      .value {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &__sinaletica_item {

      &__total {
        display: flex;
        gap: 5px;

        .title {
          font-size: 13px;
          font-weight: 600;
          color: #0181BA;
        }
        .value {
          font-size: 13px;
          font-weight: 400;
        }
      }
      &__list {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        .title {
          font-size: 12px;
          font-weight: 600;
          color: #0181BA;
        }
        .value {
          font-size: 12px;
          font-weight: 400;
      }
      }
    }

    &__availability {
      font-weight: 600;
      font-size: 13px;
      > .free {
        color: #0181BA;
      }
      > .busy {
        color: #B74311;
      }
    }
  }

  &__calendar {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #0181BA;
    border-radius: 3px;
    padding: 6px 10px 6px 10px;
    position: relative;

    .icon {
      position: absolute;
      width: 17px;
      height: 17px;
    }

    .dates {
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      color: #0181BA;
    }
  }
}
