.user-card {
    border-radius: 5px;
    border: 1px solid var(--Cinza-Claro, #C2D1D9);
    background: #FFF;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    padding: 15px 20px;
    gap: 10px;
    margin-bottom: 10px;

    .logo {
        height: 25px;
        img {
            height: 25px;
        }
    }

    .name {
        font-weight: 300;
        font-size: 13px;
        flex: 1;
    }

    .edit-button {
        border: 1px solid #C2D1D9;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 17px;
        }
    }
}