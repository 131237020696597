.sidebar_container {
  width: 300px;
  background-color: white;
  position: fixed;
  top: 94px;
  right: -300px;
  bottom: 30px;
  z-index: 800;
  box-shadow: 0px 10px 25px 0px #00000012;
  transition: all ease-in-out 500ms;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.open {
    width: 300px;
    right: 30px;
  }

  &__header {
    margin-top: 50px;

    .title {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .selection_options {
    padding: 10px 20px 0;
    display: flex;
    justify-content: space-between;

    .select_all {
      font-size: 11px;
    }

    .clean_selection {
      color: #0181BA;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .calendar {
    margin-top: 10px;
    padding: 0 20px;
    display: flex;
    gap: 10px;

    .date {
      flex-shrink: 0;
      flex-grow: 1;
      border: 1px solid #C2D1D9;
      border-radius: 5px;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;

      &:hover {
          cursor: pointer;
      }
    }
  }

  .remove-selected {
    display: flex;
    gap: 10px;
    padding: 10px 20px 0;
    text-align: left;
    color: #0181BA;
    font-weight: 600;
    text-decoration: underline;

    .icon {
      width: 17px;
      height: 17px;
    }
  }

  .new_order {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    color: #0181BA;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13px;
  }
}

.toggleCart {
  position: fixed;
  top: 104px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 300ms;
  z-index: 900;
  box-shadow: 0px 10px 25px 0px #00000012;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: #4e97c5;
  gap: 10px;

  .icon {
    transition: all ease-in-out 300ms;
    img {
      height: 12px;
    }
  }

  &.open {
    right: 30px;
    box-shadow: none;

    .icon {
      transform: rotateY(180deg);
      transition: all ease-in-out 300ms;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.no_pins {
  text-align: center;
  font-size: 11px;

  .image {
    margin-top: 90px;
  }
}

.sidebar__footer {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 6px 2px #CCC;
  padding: 10px 20px 20px;
  bottom: 0;
  left: 0;
  right: 0;

  .checkbox_pdf {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .checkbox_pdf_icon {
      height: 17px;
      width: 17px;
      border: 1px solid #C2D1D9;
      border-radius: 5px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 10px;
        height: 9px;
      }
    }

    &.selected {

      .checkbox_pdf_icon {
        border: 1px solid #3BB7D2;
        background: linear-gradient(90.02deg, #0181BA 23.52%, #3BB7D2 100%);
      }
  }
  }

  .submit_order {
    background: linear-gradient(90.02deg, #0181BA 23.52%, #3BB7D2 100%);
    color: white;
    font-weight: 600;
    width: 100%;
    display: flex;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 10px 20px;
  }
}

.sidebar_container__body {
  padding: 20px;
  padding-bottom: 100px;
  height: 100%;
  overflow: auto;
  flex: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .date-item {

    .separator {
      margin-bottom: 10px;
      color:#0181BA;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
