.leaflet-container {
    height: calc( 100vh - 64px );
    z-index: 2;
}

.leaflet-popup {
    padding-bottom: 20px; /* adjust as you see fit */
}

.custom-marker-cluster {
    background-image: url('./images/cluster.svg');
    display: flex !important;
    justify-content: center;
    align-items: center;

    & > span {
        font-weight: 600;
        color: white;
        font-size: 12px;
    }
}
