.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .perfil {
        display: flex;
        align-items: center;
        gap: 12px;

        .user {
            display: flex;
            align-items: center;
            gap: 10px;

            &-logo {
                height: 35px;
                img {
                    height: 35px;
                }
            }

            .name {
                color: #0181BA;
                font-size: 13px;
                font-weight: 600;
            }
        }

        .button {
            color: #0181BA;
            font-size: 13px;
            font-weight: 600;
        }
    }
}
