.search {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    width: 350px;

    .container {
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        .title {
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
        }

        .clean_filters {
          color: #0181BA;
          font-size: 11px;
          position: absolute;
          right: 20px;
          top: 40px;
        }

        .group {
            display: flex;
            gap: 10px;

            & > div {
                flex-shrink: 0;
                width: 100%;
                flex: 1;
            }
        }

        .date {
            border: 1px solid #C2D1D9;
            border-radius: 5px;
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;

            &:hover {
                cursor: pointer;
            }
        }
    }

}
