.profile {
    min-width: 377px;
    height: 575px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &-popup {
        .MuiModal-backdrop {
            background: rgba(7, 82, 120, 0.30);
            backdrop-filter: blur(2px);
        }
    }

    &-tab:not([hidden]) {
        height: 100%;
    }

    .logo {
        height: 45px;
        img {
            height: 45px;
        }
    }

    .title {
        font-weight: 600;
        font-size: 15px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: 100%;

        .inputs {
            flex: 1;
            flex-direction: column;
            display: flex;
            gap: 20px;
        }
    }

    .errors {
        position: absolute;
        font-size: 10px;
        color: red;
    }

    input {
        border: 1px solid #C2D1D9;
        padding: 10px 20px 10px 20px;
        width: 100%;

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    select {
        border: 1px solid #C2D1D9;
        padding: 10px 20px 10px 20px;
        width: 100%;
        background-color: white;

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    button.save {
        display: flex;
        width: 100%;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        color: #FFFFFF;
        font-weight: 600;
        background: var(--Gradiente-Azul, linear-gradient(90deg, #0181BA 23.52%, #3BB7D2 100%));
    }

    .tabs {
        width: 100%;
        min-height: 30px;
        margin-bottom: 10px;
        .MuiTabs-flexContainer {
            display: flex;
            justify-content: space-between;
        }

        .MuiTabs-indicator {
            background-color: #3BB7D2;
            height: 1px;
        }
    }

    button.tab-button {
        flex: 1;
        font-weight: 600;
        font-size: 15px;
        text-transform: none;
        color: #000000;
        font-family: 'Poppins', sans-serif;
        padding: 0;
        min-height: 30px;

        &.Mui-selected {
            color: #3BB7D2
        }
    }

    button.close {
        position: absolute;
        top: 14px;
        right: 14px;
        img {
            width: 17px;
        }
    }

    button.add-new {
        border-radius: 3px;
        border: 1px solid #0181BA;
        color: #0181BA;
        padding: 15px 20px;
        font-weight: 600;
        font-size: 13px;
    }

    .users:not([hidden]) {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .users-list {
            flex: 1;
        }
    }

    .edit-header {
        display: flex;
        margin-bottom: 18px;
        align-items: center;
        justify-content: space-between;
        .icon {
            height: 20px;
            img {
                height: 20px;
            }

            &.delete {
                height: 25px;
                width: 25px;
                border: 1px solid #C2D1D9;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 17px;
                }
            }
        }

        .edit-title {
            font-size: 11px;
            text-align: center;
        }
    }
}