@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

html *
{
   color: #000000;
   font-family: 'Poppins', sans-serif;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &.hide {
    display: none;
  }
}
