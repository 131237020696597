.search-popover {
    & .MuiPopover-paper {
        border-radius: 0px 0px 3px 3px;
        border: 1px solid #3BB7D2;
    }
}

.react-datepicker {
    width: 100%;
    border: none;

    &__header {
        background-color: white;
        border: none;
    }

    &__current-month {
        font-size: 11px;
        font-weight: 600;
    }

    &__navigation-icon {
        &--next::before {
            border-color: #0181BA;
            border-width: 1.5px 1.5px 0 0;
            top: 10px;
        }
        &--previous::before {
            border-color: #0181BA;
            border-width: 1.5px 1.5px 0 0;
            top: 10px;
        }
    }

    &__day-names {
        font-size: 11px;
        font-weight: 400;
        background-color: rgba(#C2D1D94D, 0.3);
        border-radius: 5px;
        margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    &__month {
        font-size: 11px;
    }

    &__day {
       &--selected {
            background-color: #3BB7D2;
       }

       &--today {
            color: #0181BA;
            position: relative;

            &::after {
                content: "\00B7";
                font-size: 18px;
                position: absolute;
                bottom: -10px;
                right: calc(50% - 3px);
            }
       }
    }
}