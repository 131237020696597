.toggleCaption {
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 300ms;
  z-index: 900;
  box-shadow: 0px 10px 25px 0px #00000012;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  color: #4e97c5;
  gap: 10px;

  .icon {
    transition: all ease-in-out 300ms;
    transform: rotateZ(90deg);
    img {
      height: 12px;
    }
  }

  &.open {
    box-shadow: none;

    .icon {
      transform: rotateZ(90deg) rotateY(180deg);
      transition: all ease-in-out 300ms;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.caption_container {
  background-color: white;
  margin-right: 30px;
  left: 30px;
  position: fixed;
  bottom: -240px;
  z-index: 800;
  box-shadow: 0px 10px 25px 0px #00000012;
  transition: all ease-in-out 500ms;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  padding-left: 200px;
  align-items: center;
  overflow: hidden;

  &.open {
    top: unset;
    bottom: 30px;
  }

  .marker-wrapper {
    display: flex;
    gap: 20px;

    .marker-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 30px;
        height: 36px;
      }
    }
  }

  .divider {
    width: 1px;
    height: 80px;
    background-color: #C2D1D9;
  }

  .color-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;

    .color-container {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 30px;
      }
    }
  }
}
