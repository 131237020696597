.notifications {
    &.error > div {
        background-color: #B74311;
    }

    &.success > div {
        background-color: #0C9800;
    }

    .MuiSnackbarContent-message {
        color: white;
        font-size: 13px;
        font-weight: 600;
    }
}