
.leaflet-popup {
  width: 250px;

  &-content {
    margin: 0;

    &-wrapper {
      border-radius: 5px;
      padding: 0;
      padding-bottom: 1px;
    }
  }

}

.leaflet-container a.leaflet-popup-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  border-radius: 50px;
  background-color: #FFFFFF;
  width: 25px;
  height: 25px;

  > span {
    color: #0181BA;
    font-size: 23px;
  }
}
.marker-popup {

  &__header {
    position: relative;

    .street_view {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: #FFFFFF;
      padding: 5px 11px;
      border-radius: 100px;
      font-size: 13px;
      font-weight: 600;

      .street_view_link {
        color: #0181BA;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .maximize_img_btn {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 10px;
      right: 10px;
      border-radius: 50px;
      background-color: #FFFFFF;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  &__content {
    padding: 15px 20px;
    font-size: 13px;

    &__item {
      display: flex;
      gap: 5px;
      line-height: 22px;

      .title {
        color: #0181BA;
        font-weight: 600;
      }

      .value {
        color: #000000;
        font-weight: 300;
      }
    }

    &__availability{
      .free {
        font-size: 13px;
        color: #0181BA;
        font-weight: 600;

        &.small {
          font-size: 11px;
          font-weight: 600;
          transform: translate(0, 8px);
        }

      }
      .busy {
        font-size: 13px;
        color: #B74311;
        font-weight: 600;

        &.small {
          font-size: 10px;
          font-weight: 600;
          transform: translate(0, 8px);
        }
      }
    }

    &__buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 150px;
      overflow: auto;

      .border_button {
        width: 100%;
        padding: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #0181BA;
        line-height: 22px;
        border-radius: 3px;
        border: 1px solid #0181BA;
        background: #FFFFFF;
      }


      .normal_button {
        width: 100%;
        padding: 6px;
        font-size: 13px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 22px;
        border-radius: 3px;
        background: linear-gradient(90.02deg, #0181BA 23.52%, #3BB7D2 100%);

        &.ativo {
          background: linear-gradient(90.02deg, #b74212 23.52%, #ca8060 100%);
        }

        &.rescindiu {
          background: linear-gradient(90.02deg, #e4842b 23.52%, #eab789 100%);
        }

        &.oferta {
          background: linear-gradient(90.02deg, #13c0a1 23.52%, #63f5da 100%);
        }

        &.fantasma {
          background: linear-gradient(90.02deg, #a3a3a3 23.52%, #e7e7e7 100%);
        }
      }

      .disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
      }
    }

    &__sinaletica {
      display: flex;
      justify-content: space-between;

      &_wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 13px;

        .sinaletica_direction {
          color: #505050;
          font-size: 11px;
          font-weight: 400;
          line-height: 150%;
          text-align: center;
        }

        .sinaletica_add {

          &_wrapper {
            margin-top: 8px;
            width: 100px;
            height: 35px;
            border: 1px solid #C2D1D9;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &_operator {
            width: 35px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          &_value {
            line-height: 35px;
          }
        }
      }
    }
  }


}

.image-popup-show {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 5px;
  padding: 2px;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
  max-width: calc(100vw - 60px);

  img {
    border-radius: 5px;
  }
//   width: 20vw;
//   height: 50vh;
//   z-index: 999;
}
