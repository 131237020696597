.login-form {
    height: 100vh;
    background: linear-gradient(181deg, rgba(234, 248, 253, 0.00) 1.26%, #EAF8FD 54.89%, #EAF8FD 81.75%, rgba(234, 248, 253, 0.00) 102.15%);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 70px;
    margin-top: 200px;

    .logo {
        height: 50px;

        img {
            height: 50px;
        }
    }

    .form-card {
        min-width: 300px;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .user-logo {
            height: 45px;

            img {
                height: 45px;
            }
        }

        .title {
            font-weight: 600;
            font-size: 15px;
        }

        .form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
        }

        .errors {
            font-size: 10px;
            color: red;
        }

        input {
            border: 1px solid #C2D1D9;
            padding: 10px 20px 10px 20px;
            width: 100%;

            &:focus {
                outline: none;
            }
        }

        button {
            display: flex;
            width: 100%;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            color: #FFFFFF;
            font-weight: 600;
            background: var(--Gradiente-Azul, linear-gradient(90deg, #0181BA 23.52%, #3BB7D2 100%));
        }
    }
}